function UiPagination(elm, options) {
    this.options = $.extend({range: 2, selected: $(elm).data("selected") || 1,
        size: $(elm).data("size") || 1}, options);
    var self = this;
    this.node = elm;
//        var textSize = $("li", elm).last().text().length;
    $(elm).data("selected", this.options.selected);
    $(elm).on("setOptions",function (e, pages, page) {
        self.options = {range: self.options.range, size: pages, selected: page};
        $(elm).data("selected", page);
        self.update();
    });

    $(elm).addClass("ui-pagination");

//        $(elm).empty();
//        $(".ui-pagination-pages", elm).append('<li class="next"><a href="#">Next</a></li>');
    $(".ui-pagination-pages .page", elm).remove();
    for (var i = 0; i < self.options.range * 4 + 3; i++) {
        $(".ui-pagination-pages", elm).prepend('<li class="page"><span class="title">...</span></li>');
    }

//            $("li.page", elm).css({width:(textSize * 7)+"px"});
//        $(".ui-pagination-pages", elm).prepend('<li class="prev"><a href="#">Prev</a></li>');
//        $("a", elm).wrapInner('<span class="title">');


    $(".page", elm).on("mousedown", function (e) {
        if (!$(this).hasClass("disabled")) {
            self.options.selected = parseInt($(this).text());
            $(self.node).data("selected", self.options.selected);
            $(self.node).trigger("change", [self.options.selected]);
            self.update();
        }
        e.preventDefault();
        return false;
    });

    $(".ui-pagination-next", elm).on("mousedown", function (e) {
        if (!$(this).hasClass("disabled")) {
            self.options.selected++;
            $(self.node).data("selected", self.options.selected);
            $(self.node).trigger("change", [self.options.selected]);
            self.update();
        }
        e.preventDefault();
        return false;
    });

    $(".ui-pagination-prev", elm).on("mousedown", function (e) {
        if (!$(this).hasClass("disabled")) {
            self.options.selected--;
            $(self.node).data("selected", self.options.selected);
            $(self.node).trigger("change", [self.options.selected]);
            self.update();
        }
        e.preventDefault();
        return false;
    });
    $("li", elm).mousedown(function (event) {
        event.preventDefault();
    });
    self.update();


//        $(window).resize(function () {
//            var w1 = $(elm).width();
//            var w2 = 0;
//            $("li", elm).each(function () {
//                w2 += $(this).outerWidth(true);
//            });
//            if (w2 > w1) {
//                self.options.range -= 1;
//                self.update();
//            }
//        });
}

UiPagination.prototype.update = function () {
    var elm = this.node, options = this.options, size = this.options.size, selected = this.options.selected;
    $(elm).data("selected", selected);
    $("li.page", elm).show().filter(function () {
        return $(this).index() >= size;
    }).hide();

    $("li", elm).removeClass("selected").addClass("disabled");
    $("li.page", elm).find(".title").text('...');
    if (size > options.range * 4 + 3) {
        if (selected <= options.range * 2 + 2) {
            $("li.page", elm).eq(selected - 1).addClass("selected");
        } else if (selected >= size - options.range * 2 - 1) {
            $("li.page", elm).eq(options.range * 4 + 2 + selected - size).addClass("selected");      // 23 - 18 = 5
        } else {
            $("li.page", elm).eq(options.range * 2 + 1).addClass("selected");
        }
    } else {
        $("li.page", elm).eq(selected - 1).addClass("selected");
    }
    for (var i = 0; i < options.range * 4 + 3; i++) {
        if (size > options.range * 4 + 3) {
            if (selected <= options.range * 2 + 2) {
                if (i < options.range * 3 + 2) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(i + 1);
                } else if (i > options.range * 3 + 2) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(size
                        - options.range * 4 - 2 + i);
                }
            } else if (selected >= size - options.range * 2 - 1) {
                if (i < options.range) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(i + 1);
                } else if (i > options.range) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(size
                        - options.range * 4 - 2 + i);
                }
            } else {
                if (i < options.range) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(i + 1);
                }
                if (i > options.range * 3 + 2) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(size
                        - options.range * 4 - 2 + i);
                }
                if (i > options.range && i < options.range * 3 + 2) {
                    $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(selected
                        - options.range * 2 - 1 + i);
                }
            }
        } else {
            $("li.page", elm).eq(i).removeClass("disabled").find(".title").text(i + 1);
        }

    }
    if (selected > 1) {
        $(".ui-pagination-prev", elm).removeClass("disabled");
    } else {
        $(".ui-pagination-prev", elm).addClass("disabled");
    }
    if (selected < size) {
        $(".ui-pagination-next", elm).removeClass("disabled");
    } else {
        $(".ui-pagination-next", elm).addClass("disabled");
    }
};


$.fn.uiPagination = function (options) {
    return this.each(function () {
        new UiPagination(this, options);
    });
};
