function UiCalendar(elm, options) {
    var $elm = $(elm);
    options = $.extend({
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        dayNames: ['Pon', 'Tor', 'Sre', 'Čet', 'Pet', 'Sob', 'Ned'],
        todayName: "Danes",
        clearName: "Počisti",
        sunFirst: false,
        months: 1
    }, options);
    var self = this;
    if (options.sunFirst) {
        options.dayNames.splice(0, 0, options.dayNames.pop());
    }
    $elm.addClass("ui-calendar");
    var now = new Date();
    var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    var selectedMonth = today.getMonth();
    var selectedYear = today.getFullYear();
    var selectedYearMidRange = selectedYear;
    var mode = "days";
    var nextMonth = function () {
        selectedMonth++;
        if (selectedMonth == 12) {
            selectedMonth = 0;
            selectedYear++;
            selectedYearMidRange = selectedYear;
        }
    };
    var prevMonth = function () {
        selectedMonth--;
        if (selectedMonth == -1) {
            selectedMonth = 11;
            selectedYear--;
            selectedYearMidRange = selectedYear;
        }
    };
    var setMonth = function (m) {
        m = m < 0 ? 0 : m;
        m = m > 11 ? 11 : m;
        selectedMonth = m;
    };
    var nextYear = function () {
        selectedYear++;
        selectedYearMidRange = selectedYear;
    };
    var prevYear = function () {
        selectedYear--;
        selectedYearMidRange = selectedYear;
    };
    var setYear = function (y) {
        selectedYear = y;
        selectedYearMidRange = selectedYear;
    };
    var nextYearMidRange = function () {
        selectedYearMidRange += 12;
    };
    var prevYearMidRange = function () {
        selectedYearMidRange -= 12;
    };

    function createTableHeader() {
        var table = '';
        table += '<ul class="ui-calendar-year">';

        // table += '<li class="prev-cell"></li>';
        if (mode == "days") {
            table += '<li class="year-cell"><span>' + options.monthNames[selectedMonth]
                + ' ' + selectedYear + '</span></li>';
        } else if (mode == "months") {
            table += '<li class="year-cell"><span>' + selectedYear + '</span></li>';
        } else if (mode == "years") {
            table += '<li class="year-cell"><span>' + (selectedYearMidRange - 5) + '-' + (selectedYearMidRange + 6) + '</span></li>';
        }
        // table += '<li class="next-cell"></li>';
        table += '</ul>';
        return table;
    }

    function createTableContent(type) {
        var html = "";
        if (type == "days") {
            return createContentDays();
        } else if (type == "months") {
            return createContentMonths();
        } else if (type == "years") {
            return createContentYears();
        } else if (type == "buttons") {
            return createContentButtons();
        }
        return "";
    }

    function createContentButtons() {
        var table = '';
        table += '<ul class="ui-calendar-buttons">';
        table += '<li class="button-cell button-today"><span>' + options.todayName + '</span></li>';
        if (options.clearName) {
            table += '<li class="button-cell button-clear"><span>' + options.clearName + '</span></li>';
        }

        table += '</ul>';
        return table;
    }

    function createContentWeekDays() {
        var table = '';
        table += '<ul class="ui-calendar-week">';
        for (var d = 0; d < 7; d++) {
            table += '<li class="dayName-cell"><span>' + options.dayNames[d] + '</span></li>';
        }
        table += '</ul>';
        return table;
    }

    function createContentMonths() {
        var table = '';
        table += '<ul class="ui-calendar-months">';
        for (var m = 0; m < options.monthNames.length; m++) {
            var todayClass = "";
            if (m == selectedMonth) {
                todayClass = " today";
            }
            table += '<li class="monthName-cell pick-cell' + todayClass + '">';
            table += '<span>' + options.monthNames[m] + '</span>';
            table += '</li>';
        }
        table += '</ul>';
        return table;
    }

    function createContentYear(year) {
        var table = '';
        var todayClass = "";
        if (year == selectedYear) {
            todayClass = " today";
        }
        table += '<li class="yearName-cell pick-cell' + todayClass + '">';
        table += '<span>' + year + '</span>';
        table += '</li>';
        return table;
    }

    function createContentYears() {
        var table = '', y;
        table += '<ul class="ui-calendar-years">';
        for (y = 5; y > 0; y--) {
            table += createContentYear(selectedYearMidRange - y);
        }
        for (y = 0; y < 7; y++) {
            table += createContentYear(selectedYearMidRange + y);
        }
        table += '</ul>';
        return table;
    }

    function createContentDays() {
        var table = '';
        var firstDay, prev_days;




        var year = selectedYear;
        var month = selectedMonth - 1;
        for (var m1 = 0; m1 < options.months; m1++) {
            month++;
            if (month === 12) {
                month = 0;
                year++;
            }
            // table += '<div class="ui-calendar-month">';

            table += '<div class="ui-calendar-month month-' + (m1 + 1) + '" style="max-width:' + (1 / options.months * 100) + '%">';
            table += '<ul class="ui-calendar-year">';
            // table += '<li class="next-cell"></li>';
            table += '<li class="year-cell"><span>' + options.monthNames[month]
                + ' ' + year + '</span></li>';
            table += '</ul>';
            table += createContentWeekDays();
            var days = self.getDaysInMonth(month, year);
            var firstDayDate = new Date(year, month, 1);
            firstDay = firstDayDate.getDay();

            var prev_m = month == 0 ? 11 : month - 1;
            var prev_y = prev_m == 11 ? year - 1 : year;

            var next_m = month == 11 ? 0 : month + 1;
            var next_y = next_m == 0 ? year + 1 : year;


            prev_days = self.getDaysInMonth(prev_m, prev_y);
            if (!options.sunFirst) {
                firstDay = firstDay == 0 ? 6 : firstDay - 1;
            }
            var i = 0;
            var t = days + firstDay <= 35 ? 35 : 42;
            table += '<ul class="ui-calendar-day ' + (t == 35 ? "day-rows-5" : "day-rows-6") + '">';

            for (var j = 0; j < t; j++) {
                var timeStamp = "";
                if (j < firstDay) {
                    timeStamp = prev_y + "-" + fNumber(prev_m + 1, 2) + "-" + fNumber(prev_days - firstDay + j + 1, 2);

                    table += '<li class="day-cell other-month'+(m1 > 0 ? ' hidden' :'')+'" data-date="' + timeStamp + '"><span class="day">' +
                        (prev_days - firstDay + j + 1) + '</span></li>';
                } else if (j >= firstDay + self.getDaysInMonth(month, year)) {
                    i = i + 1;
                    timeStamp = next_y + "-" + fNumber(next_m + 1, 2) + "-" + fNumber(i, 2);
                    table += '<li class="day-cell other-month'+(m1 < options.months - 1 ? ' hidden' :'')+'" data-date="' + timeStamp +
                        '"><span class="day">' + i + '</span></li>';
                } else {
                    var d1 = j - firstDay + 1;
                    var todayClass = "";
                    if (self.compareDate(new Date(year, month, d1), today) == 0) {
                        todayClass = " today";
                    }
                    timeStamp = year + "-" + fNumber(month + 1, 2) + "-" + fNumber(d1, 2);
                    table += '<li class="current-month day-cell day' + d1 + todayClass
                        + '" data-date="' + timeStamp + '"><span class="day">' + d1 + '</span></li>';
                }
            }
            table += '</ul>';
            table += '</div>';
        }


        return table;
    }

    function fNumber(n, len) {
        var a = n.toString();
        while (a.length < len) {
            a = "0" + a;
        }
        return a;
    }

    function setMode(type) {
        var table = '';
        table += '<span class="prev-cell fa fa-angle-left"></span>';
        table += '<span class="next-cell fa fa-angle-right"></span>';
        if (type == "days") {
            // table += createTableContent("weekDays");
            table += createTableContent("days");
        } else {
            table += '<div class="ui-calendar-month">';
            table += createTableHeader();
            table += createTableContent(type);
            table += '</div>';
        }
        table += createTableContent("buttons");
        mode = type;
        $(elm).html(table);
        $(".next-cell", elm).mousedown(function (e) {
            e.preventDefault();
            if (mode == "days") {
                nextMonth();
            } else if (mode == "months") {
                nextYear();
            } else if (mode == "years") {
                nextYearMidRange();
            }
            update();
            return false;
        });
        $(".button-cell", elm).mousedown(function (e) {
            e.preventDefault();
            if ($(this).hasClass("button-today")) {
                var now = new Date();
                $elm.trigger("calendar.setDate", [
                    {month: now.getMonth(), year: now.getFullYear(), day: now.getDate()}
                ]);
                $(".day-cell.today", $elm).trigger("mousedown");
            } else {
                // $(".day-cell", $elm).eq(0).data("date","").trigger("mousedown");
                $elm.trigger("calendar.clear");
            }
            update();
            return false;
        });
        $(".prev-cell", elm).mousedown(function (e) {
            e.preventDefault();
            if (mode == "days") {
                prevMonth();
            } else if (mode == "months") {
                prevYear();
            } else if (mode == "years") {
                prevYearMidRange();
            }
            update();
            return false;
        });
        $(".year-cell", elm).mousedown(function (e) {
            e.preventDefault();
            if (mode == "days") {
                mode = "months";
            } else if (mode == "months") {
                mode = "years";
            }
            update();
            return false;
        });
        $(".pick-cell", elm).mousedown(function (e) {
            e.preventDefault();
            if (mode == "months") {
                setMonth($(this).index());
                mode = "days";
            } else if (mode == "years") {
                setYear(parseInt($(this).text()));
                mode = "months";
            }
            update();
            return false;
        });
        $(elm).trigger("calendar.monthChanged", [ui.getSelectedMonth()]);
    }


    var ui = {
        getSelectedMonth: function () {
            return {year: selectedYear, month: selectedMonth}
        },
        setSelectedMonth: function (year, month) {
            selectedMonth = month;
            selectedYear = year;
            if (selectedMonth === 12) {
                selectedMonth = 0;
                selectedYear++;
            }
            update();
        }
    };
    $(elm).data("ui", ui);

    var update = function () {
        setMode(mode);
    };
    this.setSelected = function (d) {
        selectedMonth = d.getMonth();
        selectedYear = d.getFullYear();
        selectedYearMidRange = selectedYear;
    };

    $(elm).on({
        "calendar.setDate": function (e, d) {
            selectedMonth = d.month;
            selectedYear = d.year;
            selectedYearMidRange = selectedYear;
            today = new Date(d.year, d.month, d.day);
            mode = "days";
            update();
        }
    });
    update();
}


UiCalendar.prototype.getDaysInMonth = function (month, year) {
    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if ((month == 1) && (year % 4 == 0) && ((year % 100 != 0) || (year % 400 == 0))) {
        return 29;
    } else {
        return daysInMonth[month];
    }
};

UiCalendar.prototype.compareDate = function (d1, d2) {
    if (d1.getTime() == d2.getTime()) {
        return 0;
    }
    return d1.getTime() > d2.getTime() ? 1 : -1;
};

$.fn.uiCalendar = function (options) {
    return this.each(function () {
        new UiCalendar(this, options);
    });
};

