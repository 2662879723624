$.fn.destroy = function () {
    return this.each(function () {
        var elm = $(this).detach();
        if (elm.hasClass("destroy")) {
            elm.trigger("destroy");
        }
        elm.find(".destroy").trigger('destroy');
        elm.remove();
    });
};
$.fn.showInit = function () {
    return this.each(function () {
        var elm = $(this).show();
        elm.find(".show").trigger('show');
    });
};


$.fn.uiVal = function () {
    var ui;
    if (arguments.length == 0) {
        ui = $(this).data("ui");
        return ui && ui.getValue()
    }
    var v = arguments[0];
    return $(this).each(function () {
        ui = $(this).data("ui");
        if (ui) {
            ui.setValue(v);
        }
    });
};