var parseData = require("../utils/parseData");
$.fn.uiSelect = function (options1) {
    return this.each(function () {

        var options = $.extend({
            menu: parseData.getBoolean(this, "menu", false),
            overlay: parseData.getBoolean(this, "overlay", false)
        }, options1);

        var elm = this, $elm = $(elm);
        var select = $("select", $elm),
            selectValue = $(".ui-select-value", $elm), ul;
        if (selectValue.size() == 0) {
            selectValue = $('<span class="ui-select-value"></span>');
            $elm.append(selectValue);
        }
        if (select.size() == 0) {
            select = $('<select>');
            select.append('<option></option>');
            select.appendTo($elm);
        }

        select.css({opacity: 0});
        function isInside(x, y) {
            var width = $elm.outerWidth(),
                height = $elm.outerHeight(),
                top = $elm.offset().top,
                left = $elm.offset().left;
            return x >= left && x <= left + width && y >= top && y <= top + height;
        }

        select.css({left: 0, top: 0});
        $elm.on({
            mousemove: function (e) {
                if (isInside(e.pageX, e.pageY)) {
                    var o = $elm.offset(),
                        x = (e.pageX - o.left) - 15,
                        y = (e.pageY - o.top) - 15;
//                    select.css({left:x + "px", top:y + "px"});
                }
            }
        });


        function showOverlay() {
            ul = $("<ul></ul>");
            var optionsElm = select.find("option");
            optionsElm.each(function (i) {
                var opt = $(this);
                var text = opt.text();
                if (opt.data("html")) {
                    text = opt.data("html");
                }
                var li = $("<li>").appendTo(ul).html(text).data("ind", i);
                if (opt.is(":selected")) {
                    li.addClass("selected");
                }
            });
            ul.on({
                mousedown: function () {
                    var ind = $(this).data("ind");
                    optionsElm.prop("selected", false).eq(ind).prop("selected", true);
                    select.trigger("change");
                    ul.trigger("overlayMenu.hide");
                }
            }, "li");
            ul.on({
                "overlayMenu.hide": function () {
                    var opt = select.find(":selected");
                    ul = null;
                    $elm.removeClass("focus");
                }
            });
            ul.uiOverlayMenu({
                target: $elm,
                x: $elm.offset().left + "px",
                y: $elm.offset().top + $elm.outerHeight(true) + "px"
            }).parent().addClass("ui-select-menu");
            $elm.addClass("focus");
        }

        if (options.overlay) {
            select.hide();

            $elm.on({
                mousedown: function () {
                    if (ul) {
                        ul.trigger("overlayMenu.hide");
                    } else {
                        showOverlay();
                    }
                }
            });
        }

        function updateUi() {
            var option = select.find("option:selected");
            var text = option.text();
            if (option.data("html")) {
                text = option.data("html");
            }
            selectValue.html(text);
        }


        updateUi();
        select.on({
            change: function () {
                updateUi();
                if (options.menu) {
                    location.href = select.val();
                }
            },
            focus: function () {
                $elm.addClass("focus");
            },
            blur: function () {
                $elm.removeClass("focus");
            }
        });
        $elm.on({
            updateUi: function () {
                updateUi();
            }
        });
    });
};

