var moment = require("moment");

function UiDatePicker(elm, options) {
    options = $.extend({format: "D.M.YYYY"}, options);
    var $elm = $(elm),
        $button = $(".ui-datePicker-button", elm),
        $value = $(".ui-datePicker-input", elm),
        $input = $(".ui-datePicker-date", elm),
        $calendar = $('<div class="ui-datePicker-calendar">').uiCalendar(),
        val = $value.val();



    if (val === "now") {
        val = moment().format();
        $value.val(val);
    } else if (val.indexOf("days") === 0) {
        var days = parseInt(val.replace("days", ""));
        if (!isNaN(days)) {
            var d1 = new Date();
            d1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), 0, 0, 0, 0);
            val = moment(d1).add(days, "days").format();
            $value.val(val);
        }
    }



    function updateInput() {
        var d = moment($value.val());
        if (d && d.isValid()) {
            $input.val(d.format(options.format));
        } else {
            $input.val($value.val())
        }
    }

    function updateValue() {
        var d = moment($input.val(), options.format), val2;
        if (options.utc) {
            d = moment.utc($input.val(), options.format)
        }
        if (d && d.isValid()) {
            val2 = d.format();
        } else {
            val2 = $input.val();
        }
        if (val2 != val) {
            $elm.trigger("change");
        }
        $value.val(val2);
    }

    $input.on({
        keyup: updateValue,
        mouseup: updateValue,
        blur: updateValue,
        focus: function (e) {
            e.preventDefault();
            $(this).trigger("blur");
            if (!$input.prop("readonly")) {
                var d = moment($value.val());
                if (d && d.isValid()) {
                    $calendar.trigger("calendar.setDate", [
                        {month: d.month(), year: d.year(), day: d.date()}
                    ]);
                }


                $calendar.uiOverlayMenu({
                    target: elm,
                    detach: true,
                    x: $input.offset().left + "px",
                    y: $input.offset().top + $input.outerHeight(true) + "px"
                }).parent().addClass("ui-datePicker-menu");
            }
        }
    });
    $elm.addClass("destroy").on({
        "destroy": function () {
            $calendar.remove();
        }
    });
    $calendar.on({
        mousedown: function () {
//            console.log($(this).data("date"));
            var d = moment($(this).data("date")).format();
            if (options.utc) {
                d = moment.utc($(this).data("date")).format();
            }
            $value.val(d);
            $calendar.trigger("overlayMenu.hide");
            updateInput();
            $elm.trigger("change");
        }
    }, ".day-cell");

    $calendar.on({
        "calendar.clear": function () {
            $value.val("");
            $calendar.trigger("overlayMenu.hide");
            updateInput();
            $elm.trigger("change");
        }
    });

    $button.mousedown(function (e) {
        e.preventDefault();
        $input.focus();
    });




    updateInput();
    $elm.on({
        updateUi: function () {
            updateInput();
        },
        "datePicker.change": function () {
            updateInput();
        }
    });
}

$.fn.uiDatePicker = function (options) {
    return this.each(function () {
        new UiDatePicker(this, options);
    });
};
