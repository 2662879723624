$.fn.uiLoader = function (options) {
    options = $.extend({}, options);
    return this.each(function () {
        var $elm = $(this), $clock = $('<div class="ui-loader-clock">').appendTo($elm);
        var $image = $('<div class="ui-loader-image">').appendTo($clock);
        $elm.addClass("ui-loader");
        $clock.css({display: "none"});
        var scrollFn = function () {
            var elmTop = $elm.offset().top;
            var elmHeight = $elm.height();
            var windowHeight = $(window).height();
            var windowScrollTop = $(window).scrollTop();

            var top = Math.max(elmTop, windowScrollTop);
            var bottom = Math.min(elmTop + elmHeight, windowScrollTop + windowHeight);
            var a = (bottom - top) / 2;
            //console.log({
            //    elmTop: elmTop,
            //    elmHeight: elmHeight,
            //    windowHeight: windowHeight,
            //    windowScrollTop: windowScrollTop,
            //    top: top,
            //    bottom: bottom,
            //    a: a,
            //    b: a + elmTop - windowScrollTop
            //});
            $image.css({top: (a + Math.max(windowScrollTop - elmTop, 0)) + "px"});
        };

        function stop() {
            $elm.removeClass("ui-loading");
            $clock.css({display: "none"});
            $(window).off("scroll", scrollFn);
        }


        $elm.on({
            "loader.stop": function (e) {
                stop();
            },
            "loader.stopOne": function (e) {
                stop();
                e.stopPropagation();
            },
            "loader.start": function (e) {
                e.stopPropagation();
                $(window).on("scroll", scrollFn);
                var elmTop = $elm.offset().top;
                var elmHeight = $elm.height();
                var windowHeight = $(window).height();
                var windowScrollTop = $(window).scrollTop();

                var top = Math.max(elmTop, windowScrollTop);
                var bottom = Math.min(elmTop + elmHeight, windowScrollTop + windowHeight);
                var a = (bottom - top) / 2;
                $image.css({top: (a + Math.max(windowScrollTop - elmTop, 0)) + "px"});

                $elm.removeClass("ui-loading");
                $elm.addClass("ui-loading");
                $clock.css({opacity: 0.7, display: "block"});
            }
        })
    });
};

