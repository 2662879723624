$.fn.uiCheckBox = function (options) {
    options = $.extend({}, options);
    return this.each(function () {
        var elm = $(this);
        var checkbox = $("input", elm);
        checkbox.css({opacity: 0});
        elm.bind({
            click: function () {

            }
        });

        checkbox.bind({
            change: function () {
                elm.toggleClass("selected", checkbox.is(":checked"));
                $("[name='" + checkbox.attr("name") + "']").parents(".ui-checkbox").removeClass("selected");
                $("[name='" + checkbox.attr("name") + "']:checked").parents(".ui-checkbox").addClass("selected");
            },
            focus: function () {
                elm.addClass("focus");
            },
            blur: function () {
                elm.removeClass("focus");
            }
        });

        $("[name='" + checkbox.attr("name") + "']").parents(".ui-checkbox").removeClass("selected");
        $("[name='" + checkbox.attr("name") + "']:checked").parents(".ui-checkbox").addClass("selected");

    });
};

$.fn.uiCheckbox = $.fn.uiCheckBox;