function OnMouse(elm, events, selector) {
    var $elm = $(elm),
        positionStart,
        position = {},
        target,
        preventDefault = false,
        stopPropagation = false,
        dragDetect = null,
        drag = 0,
        moved = false,
        canceled = false;
    var posObj = {
        data: {},
        position: function () {
            return {
                x: position.x,
                y: position.y
            }
        },
        detectHorizontalDrag: function (x, y) {
            dragDetect = {x: x, y: y, type: "horizontal"};
        },
        detectDrag: function (x, y) {
            dragDetect = {x: x, y: y, type: "both"};
        },
        positionStart: function (obj) {
            if (obj && obj.x) {
                positionStart.x = obj.x;
            }
            if (obj && obj.y) {
                positionStart.y = obj.y;
            }
            return {
                x: positionStart.x,
                y: positionStart.y
            }
        },
        diff: function () {
            return {
                x: position.x - positionStart.x,
                y: position.y - positionStart.y
            }
        },
        isTouch: function () {
            return position.touch;
        },
        isCanceled: function () {
            return canceled;
        },
        isDrag: function () {
            return drag == 1;
        },
        hasMoved: function () {
            return moved;
        },
        preventDefault: function () {
            preventDefault = true;
        },
        isDefaultPrevented: function () {
            return preventDefault;
        },
        stopPropagation: function () {
            stopPropagation = true;
        }
    };

    function getMouse(event) {
        return {
            x: event.pageX + (event.iframeLeft || 0),
            y: event.pageY + (event.iframeTop || 0),
            touch: false
        }
    }

    function getTouch(event) {
        var touches = event.originalEvent.touches;
        if (touches && touches.length > 0) {
            return {
                x: touches[0].pageX,
                y: touches[0].pageY,
                touch: true
            }
        }

        return null;
    }

    function detectDrag() {
        var diff = posObj.diff();
        if (dragDetect && dragDetect.type == "horizontal") {
            if (Math.abs(diff.x) > dragDetect.x && drag == 0) {
                drag = 1;
                target.trigger("drag.start", [posObj]);
                posObj.preventDefault();
            } else if (Math.abs(diff.y) > dragDetect.y && drag == 0) {
                drag = 2;
            }
        } else if (dragDetect && dragDetect.type == "both") {
            if ((Math.abs(diff.y) > dragDetect.y || Math.abs(diff.x) > dragDetect.x) && drag == 0) {
                drag = 1;
                target.trigger("drag.start", [posObj]);
                posObj.preventDefault();
            }
        }
        if (drag == 1) {
            target.trigger("drag.move", [posObj]);
        }
    }

    function detectMove() {
        if (Math.abs(position.x) > 0 || Math.abs(position.y) > 0) {
            moved = true;
        }
    }

    function mousemove(event) {

        position = getMouse(event);
        target.trigger("mouse.move", [posObj]);
        detectDrag();
        detectMove();

        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }
    }

    function touchmove(event) {

        position = getTouch(event);
        target.trigger("mouse.move", [posObj]);
        detectDrag();
        detectMove();
        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }

    }

    function scroll() {
        drag = 2;
    }

    function globalBind(name, type) {
        if ("type" == "touch") {
//            $(window).bind("touchmove", touchmove);

        } else {
            $(window)[name]({
                mouseup: mouseup,
                mousemove: mousemove
//                scroll: scroll
            });
            $(window)[name]({
                keydown: keydown
            });
        }

    }

    function mouseup(event) {
        position = getMouse(event);
        target.trigger("mouse.stop", [posObj]);
        if (posObj.isDrag()) {
            target.trigger("drag.stop", [posObj]);
        }
        drag = 0;
        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }
        globalBind("off");
    }

    function touchend(event) {
        target.trigger("mouse.stop", [posObj]);
        if (posObj.isDrag()) {
            target.trigger("drag.stop", [posObj]);
        }
        drag = 0;
        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }
        globalBind("off", "touch");
    }

    function touchcancel(event) {
        target.trigger("mouse.end", [posObj]);
        canceled = true;
        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }
    }

    function keydown(e) {
        if (e.which == 27) {
            canceled = true;
            console.log("cancel");
            position = {x: -1, y: -1};
            target.trigger("mouse.stop", [posObj]);
            if (preventDefault) {
                e.preventDefault();
            }
            if (stopPropagation) {
                e.stopPropagation();
            }
            globalBind("off");
        }
    }

    function reset() {
        canceled = false;
        moved = false;
        posObj.data = {};
        drag = 0;
        dragDetect = null;
    }

    function mousedown(event) {
        if (event.which == 1 && !posObj.isTouch()) {

            posObj.iframeLeft = event.iframeLeft = Number($(this.ownerDocument).find("body").attr("data-iframeleft") || 0);
            posObj.iframeTop = event.iframeTop = Number($(this.ownerDocument).find("body").attr("data-iframetop") || 0);
            position = positionStart = getMouse(event);
            preventDefault = event.isDefaultPrevented();
            target = $(this);
            reset();
            target.trigger("mouse.start", [posObj]);
            if (dragDetect && !posObj.isTouch()) {
                target.trigger("drag.start", [posObj]);
                posObj.preventDefault();
            }
            if (preventDefault) {
                event.preventDefault();
            }
            if (stopPropagation) {
                event.stopPropagation();
            }
            globalBind("on");
        }
    }

    function touchstart(event) {
        if (event.originalEvent.touches.length > 1) {
            return;
        }
        position = positionStart = getTouch(event);
        globalBind("on", "touch");
        preventDefault = event.isDefaultPrevented();
        target = $(this);
        reset();
        target.trigger("mouse.start", [posObj]);
        if (preventDefault) {
            event.preventDefault();
        }
        if (stopPropagation) {
            event.stopPropagation();
        }

    }

    function gesturechange(event) {
        event.preventDefault();
        $elm.trigger("mouse.scale", [posObj, event.originalEvent.scale]);
    }

    $elm.on({
        touchstart: touchstart,
        touchmove: touchmove,
        gesturechange: gesturechange,
        touchend: touchend,
//        touchcancel: touchcancel,
        mousedown: mousedown
    }, selector);

    $elm.on(events, selector);
}

$.fn.onMouse = function (events, selector) {
    return this.each(function () {
        new OnMouse(this, events, selector);
    });
};

$.isTouchDevice = function () {
    return 'ontouchstart' in window // works on most browsers
        || 'onmsgesturechange' in window; // works on ie10
};

var touchId = null;

$.fn.bindTap = function (events, selector) {
    return this.each(function () {
        var $elm = $(this);
        var touchClick = false;
        $elm.on({
            touchstart: function (e) {
                if (e.originalEvent.touches.length > 1) {
                    return;
                }

//                touchClick = true;
                touchClick = touchId = new Date().getTime();
//                alert("start");
//                e.preventDefault();
            },
            touchmove: function (e) {
//                if (e.originalEvent.touches.length > 1) {
//                    return;
//                }
//                touchClick = false;
            },
            touchend: function (e) {
                if (e.originalEvent.touches.length > 1) {
                    return;
                }
                e.preventDefault();
                if (touchClick === touchId) {
                    $(e.target).trigger("click");
                    touchClick = false;
                }
            },
            touchcancel: function () {
//                touchClick = false;
            }
        }, selector);
        $elm.on({
            gesturechange: function (e) {
                e.preventDefault();
                var scale = e.originalEvent.scale;
                alert("scale " + scale);
            },
            gestureend: function (e) {

            }
        });
        $elm.on(events, selector);
    });
};


//(function () {
//    var noTouchElements = "|SELECT|A|";
//    if ($(".lt-ie9").length > 0) {
//        $(document).on({
//            click: function (e) {
//                $(e.target).trigger("tap");
//            }
//        });
//    } else {
//        var touchClick = false;
//        var mouseEnabled = true;
//        var mouseClick = false;
//        document.addEventListener("touchstart", function () {
//            touchClick = true;
//            mouseEnabled = false;
//        }, false);
//        document.addEventListener("touchmove", function () {
//            touchClick = false
//        }, false);
//        document.addEventListener("touchend", function (e) {
//
//            if (touchClick && noTouchElements.indexOf(e.target.tagName) == -1) {
//                e.preventDefault();
//                touchClick = false;
//                var event = document.createEvent("CustomEvent");
//                event.initCustomEvent("tap", true, true, e.target);
//                e.target.dispatchEvent(event);
//            }
//        }, false);
//
//
//        document.addEventListener("mousedown", function () {
//            if (mouseEnabled) {
//                mouseClick = true
//            }
//        }, false);
//        document.addEventListener("mousemove", function () {
//            mouseClick = false
//        }, false);
//        document.addEventListener("mouseup", function (e) {
//            if (mouseClick && noTouchElements.indexOf(e.target.tagName) == -1) {
//                e.preventDefault();
//                mouseClick = false;
//                var event = document.createEvent("CustomEvent");
//                event.initCustomEvent("tap", true, true, e.target);
//                e.target.dispatchEvent(event);
//            }
//        }, false);
//    }
//}());
