var _ = require("underscore");
function getDataNumber(elm, prop, def) {
    var val = parseFloat($(elm).data(prop));
    return isNaN(val) ? def : val;
}
function getData(elm, prop, def) {
    var val = $(elm).data(prop);
    return val || def;
}

function UiSpinner(elm, options) {
    options = $.extend({
        min: getDataNumber(elm, "min"),
        max: getDataNumber(elm, "max"),
        tick: getDataNumber(elm, "tick", 1),
        mode: getData(elm, "mode", "default"),
        separator: ","
    }, options);
    var $elm = $(elm),
        $inc = $elm.find(".ui-spinner-inc"),
        $dec = $elm.find(".ui-spinner-dec"),
        $input = $elm.find(".ui-spinner-input"),
        $select;

    var items = 9;
    if (options.min && options.mode == "select" && getValue() < options.min + options.tick * items) {
        var $uiSelect = $('<span class="ui-select ui-spinner-select"><select name="qty123"></select></span>');
        $select = $uiSelect.find("select");


        var $option;
        for (var i = options.min; i < options.min + options.tick * items; i += options.tick) {
            $option = '<option value="' + i + '"' + (getValue() == i ? ' selected="selected"' : '') + '>' + i + '</option>';
            $select.append($option);
        }
        $option = '<option value="more">' + (options.min + options.tick * items) + '+</option>';
        $select.append($option);

        $inc.hide();
        $dec.hide();
        $input.hide();
        $input.after($uiSelect);
        $uiSelect.uiSelect();

        $select.on({
            change: function (e) {
                e.stopPropagation();
                e.preventDefault();
                if ($select.val() != "more") {
                    $input.val($select.val());
                } else {
                    $uiSelect.hide();
                    $input.val(options.min + options.tick * items);
                    $input.show();
                    $inc.show();
                    $dec.show();
                }
                return false;
            }
        })
    }

    function getDefaultValue() {
        if (_.isNumber(options.min)) {
            return options.min;
        } else if (_.isNumber(options.max)) {
            return options.max;
        }
        return 0;
    }

    function update() {
        var num = getDataNumber($input, "val", getDefaultValue());
        $input.data("val", num).data("textValue", num).val(num);
    }

    function getValue() {
        var num = $input.data("val");
        if (isNaN(num)) {
            num = getDefaultValue();
        }
        return num;
    }

    $input.data("val", getValue());
    $input.val($input.data("val"));


    $inc.on({
        mousedown: function (e) {
            e.preventDefault();
            var num = getValue() + options.tick;
            num = roundToDecimals(separatorToFloat(num, options.separator), options.tick);
            if (_.isNumber(options.max) && num > options.max) {
                num = options.max;
            }
            $input.data("val", num).val(floatToSeparator(num, options.separator)).data("rVal", num);
            $elm.trigger("change");
        }
    });
    $dec.on({
        mousedown: function (e) {
            e.preventDefault();
            var num = getValue() - options.tick;
            num = roundToDecimals(separatorToFloat(num, options.separator), options.tick);
            if (_.isNumber(options.min) && num < options.min) {
                num = options.min;
            }
            $input.data("val", num).val(floatToSeparator(num, options.separator)).data("rVal", num);
            $elm.trigger("change");
        }
    });


    $input.onInput({
        change: function (e) {
            e.stopPropagation();
            var num = separatorToFloat($input.val(), options.separator);
            if (isNaN(num)) {
                num = getDefaultValue();
            }
            if (_.isNumber(options.min) && num < options.min) {
                num = options.min;
            } else if (_.isNumber(options.max) && num > options.max) {
                num = options.max;
            }
            var rVal = $input.val();
            $input.data("val", num).data("rVal", rVal);
            $elm.trigger("change");
        },
        blur: function () {
            var num = roundToDecimals($input.data("val"), options.tick);
            $input.val(floatToSeparator(num, options.separator));
        }
    });

    var fixTimeout;
    $input.on({
        keyup: function () {
            var num;
            if (fixTimeout) {
                clearTimeout(fixTimeout);
            }
            fixTimeout = setTimeout(function () {
                num = roundToDecimals($input.data("val"), options.tick);
                var val1 = $input.val();
                if (!val1) {
                    return;
                }
                var val2 = floatToSeparator(num, options.separator);
                if (val1 != val2) {
                    $input.val(val2);
                }
            }, 500);
        }
    });

    update();
    $elm.on({
        updateUi: function () {
            update();
        }
    })

}

//function fixValue(value, tick, separator){
//    var num = roundToDecimals(value, tick);
//    value(floatToSeparator(num, separator));
//}

function separatorToFloat(str, separator) {
    var arr = str.toString().split(separator);
    return parseFloat(arr[0] + "." + arr[1]);
}

function floatToSeparator(str, separator) {
    var arr = str.toString().split(".");
    if (arr[1]) {
        return arr[0] + separator + arr[1];
    } else {
        return arr[0];
    }
}

function roundToDecimals(num, tick) {
    num = num || 0;
    if (Math.floor(num) === num) return num;
    num = num.toFixed(countDecimals(tick));
    if (countDecimals(tick) > 0) {
        while (num.substring(num.length - 1, num.length) == 0) {
            num = num.substring(0, num.length - 1);
        }
    }
    return num;
}

function countDecimals(num) {
    if (Math.floor(num) === num) return 0;
    return num.toString().split(".")[1].length || 0;
}

$.fn.uiSpinner = function (options) {
    options = $.extend({}, options);
    return this.each(function () {

        new UiSpinner(this, options);
    });
};

