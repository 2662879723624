$.fn.uiShare = function (options) {

    return this.each(function () {
        options = $.extend({}, options);

        var elm = this;


        var url = $(elm).data("href") || options.href || "";
        var title = $(elm).data("title") || options.title || "";
        var image = $(elm).data("image") || options.image || "";

        var html = '';
        html += '<a class="ui-share-btn ui-share-facebook"></a>';
        html += '<a class="ui-share-btn ui-share-twitter"></a>';
        if (image) {
            html += '<a class="ui-share-btn ui-share-pinterest"></a>';
        }
        html += '<a class="ui-share-btn ui-share-googleplus"></a>';
        html += '<a class="ui-share-btn ui-share-linkedin"></a>';
        html += '<a class="ui-share-btn ui-share-email"></a>';


        $(elm).html(html);
        var fb = "https://www.facebook.com/sharer/sharer.php?u=" + url;
        var tw = "http://twitter.com/intent/tweet?text=" + title + "&url=" + url;
        //var tw = "https://twitter.com/home?status=" + url;
        var gp = "https://plus.google.com/share?url=" + url + "&img=" + image;
        var em = "mailto:?&subject=" + title + "&body=" + url;
        var pin = "https://pinterest.com/pin/create/button/?url=" + url + "&media=" + image + "&description=" + title;
        var ln = "https://www.linkedin.com/shareArticle?mini=true&url=" + url + "&title=" + title + "&summary=&source=";

        $(".ui-share-facebook", this).attr("href", fb).attr("target", "_blank");
        $(".ui-share-twitter", this).attr("href", tw).attr("target", "_blank");
        $(".ui-share-email", this).attr("href", em).attr("target", "_blank");
        $(".ui-share-pinterest", this).attr("href", pin).attr("target", "_blank");
        $(".ui-share-googleplus", this).attr("href", gp).attr("target", "_blank");
        $(".ui-share-linkedin", this).attr("href", ln).attr("target", "_blank");
        $(".ui-share-email", this).attr("href", em).attr("target", "_blank");
    });

};


// https://plus.google.com/share?url=http%3A%2F%2Fshare-this-image.com%2Fsharer.php%3Furl%3Dhttp%253A%252F%252Fshare-this-image.com%252F%252341041%26img%3Dshare-this-image.com%2Fimg%2Ffeature-customize.jpg%26title%3DShare%2520This%2520Image%2520%2520Fully%2520Customizable%2520Image%2520Sharing%2520Plugin%26desc%3DShare%2520images%2520with%2520custom%2520sharing%2520content%2520Change%2520title%2520and%2520description%2520for%2520each%2520of%2520it%2341041
// <!DOCTYPE html>
//
// <html itemscope itemtype="http://schema.org/Blog">
//
//     <head>
//
//     <meta charset="UTF-8">
//     <meta property="og:type" content="article" />
//     <meta name="twitter:card" content="summary_large_image">
//     <link rel="canonical" href="http://share-this-image.com/sharer.php?url=http%3A%2F%2Fshare-this-image.com%2F%2341041&img=share-this-image.com/img/feature-customize.jpg&title=Share%20This%20Image%20%20Fully%20Customizable%20Image%20Sharing%20Plugin&desc=Share%20images%20with%20custom%20sharing%20content%20Change%20title%20and%20description%20for%20each%20of%20it"/>
//     <meta property="og:url" content="http://share-this-image.com/sharer.php?url=http%3A%2F%2Fshare-this-image.com%2F%2341041&img=share-this-image.com/img/feature-customize.jpg&title=Share%20This%20Image%20%20Fully%20Customizable%20Image%20Sharing%20Plugin&desc=Share%20images%20with%20custom%20sharing%20content%20Change%20title%20and%20description%20for%20each%20of%20it"/>
//     <meta property="twitter:url" content="http://share-this-image.com/sharer.php?url=http%3A%2F%2Fshare-this-image.com%2F%2341041&img=share-this-image.com/img/feature-customize.jpg&title=Share%20This%20Image%20%20Fully%20Customizable%20Image%20Sharing%20Plugin&desc=Share%20images%20with%20custom%20sharing%20content%20Change%20title%20and%20description%20for%20each%20of%20it"/>
//     <meta property="og:image" content="http://share-this-image.com/img/feature-customize.jpg"/>
//     <meta property="twitter:image" content="http://share-this-image.com/img/feature-customize.jpg"/>
//     <title>Share This Image  Fully Customizable Image Sharing Plugin</title>
//     <meta property="og:title" content="Share This Image  Fully Customizable Image Sharing Plugin"/>
//     <meta property="twitter:title" content="Share This Image  Fully Customizable Image Sharing Plugin"/>
//     <meta property="og:site_name" content="Share This Image  Fully Customizable Image Sharing Plugin"/>
//     <meta name="description" content="Share images with custom sharing content Change title and description for each of it">
//     <meta property="og:description" content="Share images with custom sharing content Change title and description for each of it"/>
//     <meta property="twitter:description" content="Share images with custom sharing content Change title and description for each of it"/>
//     <meta http-equiv="refresh" content="0;url=http://share-this-image.com/#41041">
//     <style type="text/css">
//     body {background:#fff;font-family: arial,helvetica,lucida,verdana,sans-serif;margin:0;padding:0;}h1 {background:#f5f5f5;border-top:1px solid #eee;border-bottom:1px solid #eee;margin-top:10%;padding:50px;font-size:1.4em;font-weight:normal;text-align:center;color:#000;}
// </style>
//
// </head>
//
// <body>
// <h1>contacting ...</h1>
// </body>
//
// </html>