//deprecated
$.fn.onInput = function (events, selector) {
    return this.each(function () {
        var $elm = $(this);


        function checkChange(e) {
            if ($(this).val() != $(this).data("textValue")) {
                $(this).trigger("change", false);
                $(this).data("textValue", $(this).val());
            }
        }

        if (selector) {
            $elm.find(selector).each(function () {
                $(this).data("textValue", $(this).val());
            });
        } else {
            $elm.data("textValue", $elm.val());
        }


        $elm.on({
            keyup: checkChange,
            updateUi: function (e) {
                e.stopPropagation();
                checkChange.call(this, e);
            },
            mouseup: checkChange
        }, selector);


        $elm.on(events, selector);

        var changeInterval = setInterval(function () {
            if (selector) {
                $elm.find(selector).trigger("updateUi");
            } else {
                $elm.trigger("updateUi");
            }

        }, 100);

        $(this).addClass("destroy").on({
            destroy: function () {
                clearInterval(changeInterval);
            }
        });


    });
};
//v2
$.fn.bindInputChange = function (selector) {
    return this.each(function () {
        var $elm = $(this);


        function checkChange(e) {
            if ($(this).val() != $(this).data("textValue")) {
                $(this).trigger("input.change", false);
                $(this).data("textValue", $(this).val());
            }
        }

        if (selector) {
            $elm.find(selector).each(function () {
                $(this).data("textValue", $(this).val());
            });
        } else {
            $elm.data("textValue", $elm.val());
        }


        $elm.on({
            keyup: checkChange,
            updateUi: function (e) {
                e.stopPropagation();
                checkChange.call(this, e);
            },
            mouseup: checkChange,
            input: checkChange
        }, selector);


        //$elm.on(events, selector);

        //TODO has beed disabled, not sure if it brakes something?
        var changeInterval;
        if (false){
            changeInterval = setInterval(function () {
                if (selector) {
                    $elm.find(selector).trigger("updateUi");
                } else {
                    $elm.trigger("updateUi");
                }

            }, 100);
        }


        $(this).addClass("destroy").on({
            destroy: function () {
                if (changeInterval) {
                    clearInterval(changeInterval);
                }
            }
        });


    });
};

