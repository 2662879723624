var parseData = require("../utils/parseData");
$.fn.uiTabs = function (options) {
    return this.each(function () {
        var options = $.extend({hover: false, showControllers: false}, options);
        var elm = this;
        options.hover = parseData.getBoolean(this, "hover", false);
        options.showControllers = parseData.getBoolean(this, "showcontrollers", false);

        $(".ui-tabs-content", elm).each(function () {
            if ($(this).closest(".ui-tabs").is($(elm))) {
                $(this).hide();
            }
        });
        function action($head) {
            var ind = $head.parent().index();
            $head.closest(".ui-tabs-header").find("li").removeClass("selected").children().removeClass("selected");
            $head.parent().addClass("selected").children().addClass("selected");

            var content = $head.closest(".ui-tabs-header").next().find(".ui-tabs-content").filter(function () {
                return $(this).closest(".ui-tabs").is($(elm));
            });

            // var content = $("> .ui-tabs-body", $head.closest(".ui-tabs")).find(".ui-tabs-content").filter(function () {
            //     return $(this).closest(".ui-tabs").is($(elm));
            // });

            content.hide();
            content.eq(ind).show().trigger("tab.contentVisible");
            content.eq(ind).find(".show").trigger("show");
            $(elm).trigger("tab.change");
            return false;
        }

        if (options.showControllers) {                                                       //todo vretno ne dela ce so li-ji empty-content
            $(".ui-tabs-header", elm).each(function () {
                var tabs = $(this).closest(".ui-tabs");
                if (tabs.is($(elm))) {
                    var header = $(this);
                    var controllers = $('<div class="ui-tabs-controllers"></div>');
                    var left = $('<div class="ui-tabs-controller left"><</div>');
                    var right = $('<div class="ui-tabs-controller right">></div>');
                    controllers.append(left);
                    controllers.append(right);
                    header.append(controllers);
                    left.click(function () {
                        var cur = header.find("li.selected").index();
                        if (cur == 0) {
                            goToEq(header, header.find("li").length - 1);
                        } else {
                            goToEq(header, cur - 1);
                        }
                    });
                    right.click(function () {
                        var cur = header.find("li.selected").index();
                        if (cur == header.find("li").length - 1) {
                            goToEq(header, 0);
                        } else {
                            goToEq(header, cur + 1);
                        }
                    });
                }
            });
        }

        if (options.hover) {
            $(".ui-tabs-header a", elm).each(function () {
                var tabs = $(this).closest(".ui-tabs");
                if (tabs.is($(elm))) {
                    $(this).mouseenter(function () {
                        return action($(this));
                    });
                    if (!tabs.data("inited") && $(this).parent().hasClass("selected")) {
                        tabs.data("inited", true);
                        $(this).trigger("mouseenter");
                    }
                }
            })
        } else {
            $(".ui-tabs-header a", elm).each(function () {
                var tabs = $(this).closest(".ui-tabs");
                if (tabs.is($(elm))) {
                    $(this).click(function () {
                        return action($(this));
                    });
                    if (!tabs.data("inited") && $(this).parent().hasClass("selected") && !$(this).parent().hasClass("empty-content")) {
                        tabs.data("inited", true);
                        $(this).trigger("click");
                    }
                }
            });
        }

        if (!$(elm).data("inited")) {
            $(".ui-tabs-header a", elm).each(function () {
                var tabs = $(this).closest(".ui-tabs");
                if (tabs.is($(elm)) && !tabs.data("inited") && !$(this).parent().hasClass("empty-content")) {
                    tabs.data("inited", true);
                    if (options.hover) {
                        $(this).trigger("mouseenter");
                    } else {
                        $(this).trigger("click");
                    }
                }
            });
        }

        function goToEq(header, eq) {
            header.find("li").eq(eq).find("a").trigger(options.hover ? "mouseenter" : "click");
        }
    });
};



