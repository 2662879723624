function UiOverlayMenu(elm, options) {
    options = $.extend({x: 0, y: 0, detach: false}, options);
    $("#ui-overlayMenu > *").trigger("overlayMenu.hide");
    var $elm = $(elm);
    var menu = $('<div id="ui-overlayMenu">').append(elm);


    function windowResize() {
        $elm.trigger("overlayMenu.hide");
    }

    function documentMouse(e) {
        if ((options.target && $(e.target).closest(options.target).size() == 0)
            && $(e.target).closest(menu).size() == 0) {
            $elm.trigger("overlayMenu.hide");
        }
    }


    function keyDown(e) {
        if (e.which == 27) {
            $elm.trigger("overlayMenu.hide");
        }
    }

    $(window).on("keydown", keyDown);

    $(window).on("resize", windowResize);
    $(document).on("mousedown", documentMouse);

    menu.on({
        hideOverlay: windowResize,
        updateUi: function () {

        }
    });

    function hideMenu() {
        $(window).off("resize", windowResize);
        $(document).off("mousedown", documentMouse);
        $(document).off("keydown", keyDown);
        if (options.detach) {
            $("body").append($elm);
        }
        menu.remove();
        $elm.trigger("overlayMenu.closed");
        $elm.off("overlayMenu.hide", hideMenu);

    }

    $elm.on("overlayMenu.hide", hideMenu);

    menu.appendTo("body");

    if (options.x < 0) {
        options.x = 0;
    }
    if (options.y < 0) {
        options.y = 0;
    }

    menu.css({position: "absolute", left: options.x, top: options.y, zIndex: 1});
    var docHeight = $(window).height();

    setTimeout(function () {
        if (menu.width() + parseInt(options.x) > $(window).width()) {
            menu.css({left: ($(window).width() - menu.width()) + "px"});
        }
        // console.log($("body").css("overflow"), menu.height() + parseInt(options.y), docHeight);
        if ($("body").css("overflow") === "hidden") {
            if (menu.height() + parseInt(options.y) > docHeight) {
                menu.css({top: Math.max(0, docHeight - menu.height()) + "px"});
            }
        }

    }, 10);

}


$.fn.uiOverlayMenu = function (options) {
    return this.each(function () {
        new UiOverlayMenu(this, options);
    });
};