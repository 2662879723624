$.fn.uiSiteScroll = function (options) {
    options = $.extend({}, options);
    return this.each(function () {
        var $elm = $(this);
        var SCROLLBAR_WIDTH = require("./metrics").getScrollBarWidth();
        var scrollTop = $(window).scrollTop();
        // var overlay = $('<div>').css({
        //     position: "fixed",
        //     height: "100vh",
        //     width: "100vw",
        //     top: 0,
        //     left: 0,
        //     overflow: "scroll",
        //     display: "none",
        //     background: "rgba(0, 0, 0, 0.5)",
        //     zIndex: 99
        // }).appendTo("body");
        $(".ui-siteScroll-page").css({minHeight: "100vh"});
        var direction = 1;

        var slide = -1;
        var bump = 0;
        var animating = false;

        function goToSlide(eq) {

            // overlay.show();
            // console.log("slide " + slide + " > " + eq);
            var pages = $(".ui-siteScroll-page");
            var size = pages.length;
            var page = pages.eq(eq);
            if (eq === -1 || page.length === 0) {
                return;
            }
            var top = page.position().top;
            var height = page.outerHeight(true);
            var wh = $(window).height();
            if (eq === slide) {
                bump = 0;
                // console.log("gotoslide", eq, scrollTop < top, scrollTop > top + height - wh);
                if (scrollTop < top) {
                    scrollAnimate(top);
                } else if (scrollTop > top + height - wh) {
                    scrollAnimate(top + height - wh);
                }
            } else {
                slide = eq;
                bump = 0;
                var st = top;
                if (direction < 0 && slide > 0) {
                    st += height - wh;
                    if (height > wh) {
                        bump = top;
                    }
                    // console.log(height - $(window).height())
                } else if (direction > 0 && slide < size - 1) {
                    if (height > wh) {
                        bump = top + height - wh;
                    }
                }
                scrollAnimate(st);
            }

        }

        function getSlideAfterScroll() {
            // animating = true;

            var sh = $(window).height();
            var pageFound = false;
            $(".ui-siteScroll-page").each(function () {
                var top = $(this).position().top;
                var height = $(this).outerHeight(true);
                if (direction === 1) {
                    // console.log("direction", {direction: direction, height: height, top: top, scrollTop: scrollTop});
                    if (scrollTop + sh > top && scrollTop < top) {
                        // console.log("getSlideAfterScroll", $(this).find(".pageNumber").text());
                        goToSlide($(this).index(".ui-siteScroll-page"));
                        pageFound = true;
                    }
                } else {
                    // console.log("direction", {direction: direction, height: height, top: top, scrollTop: scrollTop});
                    if (scrollTop + sh > top + height && scrollTop < top + height) {
                        // console.log("getSlideAfterScroll", $(this).find(".pageNumber").text());
                        goToSlide($(this).index(".ui-siteScroll-page"));
                        pageFound = true;
                    }
                }

            });
            if (!pageFound) {
                // animating = false;
            }
        }

        // setInterval(getSlideAfterScroll, 50);

        function scrollAnimate(st) {
            if (tm) {
                clearTimeout(tm);
            }
            // if (animating) {
            //     return;
            // }
            animating = true;
            // console.log("animate", {top: st, scrollTop: scrollTop, bump: bump, direction: direction}, st, scrollTop);
            var htmlBody = $("html, body");
            // $(window).css({paddingRight: SCROLLBAR_WIDTH + "px", overflow: "hidden"});
            TweenLite.killTweensOf(window);
            var time1 = Math.min(Math.abs(st - scrollTop) * 1.2 / 1000, 2);
            TweenLite.to(window, time1, {
                ease: Power2.easeOut,
                scrollTo: st, onComplete: function () {
                    // console.log("animation stop");


                }
            });
            setTimeout(function () {
                // console.log("animation released");
                animating = false;
                // $(window).css({paddingRight: "", overflow: ""});
            }, time1 * 1000 + 50);

            // .animate({scrollTop: st + "px"},
            //     Math.abs(st - scrollTop) * 6, function () {
            //         // overlay.hide();
            //         $("body").css({paddingRight: ""});
            //         htmlBody.css({
            //             overflow: ""
            //         });
            //         console.log("animation stop");
            //         animating = false;
            //     })
        }

        function mousedown() {
            animating = true;
            bump = 0;
            // console.log("m down");
        }

        function mouseup() {
            animating = false;
            // console.log("m up");
        }

        $(window).on({
            mousedown: mousedown,
            mouseup: mouseup,
            touchstart: mousedown,
            touchend: mouseup,
        });


        setInterval(function () {
            var st = $(window).scrollTop();
            if (animating) {
                return;
            }
            if (st === scrollTop) {
                goToSlide(slide);
                return;
            }
            if (st >= scrollTop) {
                if (direction === -1) {
                    bump = 0;
                }
                direction = 1;
            } else {
                if (direction === 1) {
                    bump = 0;
                }
                direction = -1;
            }
            scrollTop = st;
            if (tm) {
                clearTimeout(tm);
            }
            if (bump > 0) {

                if (direction === -1 && bump > scrollTop) {
                    // console.log("bump -1", {direction: direction, bump: bump, scrollTop: scrollTop});
                    // animating = true;
                    scrollAnimate(bump);
                    bump = 0;
                    return;
                } else if (direction === 1 && bump < scrollTop) {
                    // console.log("bump 1", {direction: direction, bump: bump, scrollTop: scrollTop});
                    // animating = true;
                    scrollAnimate(bump);
                    bump = 0;

                }
                return;
            }
            // console.log("set tm", bump);
            // if (!animating){
            tm = setTimeout(getSlideAfterScroll, 50);
        }, 100);
        var tm;
        $(window).on("scroll", function (e) {
            if (tm) {
                clearTimeout(tm);
            }
            // }

        });

        tm = setTimeout(getSlideAfterScroll, 50);
        var ui = {
            goToSlide: function (slide) {
                bump = 0;
                direction = 1;
                goToSlide(slide);
            }
        };
        $elm.data("ui", ui);

    });
};


