var _ = require("underscore");

exports.getNumber = function (elm, prop, def) {
    def = def || 0;
    var val = Number($(elm).data(prop));
    return isNaN(val) ? def : val;
};


exports.getBoolean = function (elm, prop, def) {
    var val = $(elm).data(prop);
    if (_.isUndefined(val)) {
        return !!def;
    }
    return true;
};

exports.getString = function (elm, prop, def) {
    def = def || "";
    var val = $(elm).data(prop);
    if (_.isUndefined(val)) {
        return def;
    }
    return val;
};

exports.getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};